import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import ReCAPTCHA from "react-google-recaptcha";

export default () => (
    <Container>
        <Helmet>
            <title>An Exquisite Meal | Watch the Film </title>
            <meta name="description" content="An ambitious dark comedy thriller." />
            <meta name="google-site-verification" content="9Mpk8j_WsmSP30bEXxV4apqdr2VVJWB5ev4RwtxDtoc" />
            <script dangerouslySetInnerHTML={{
                __html: `<script> src="https://player.vimeo.com/api/player.js"></script>`
                }}
            />
        </Helmet>

        <div class="sub-container-home">

            <div className="home-header">
                <img class="poster-image" src="../AnExquisiteMeal_2000x3000_LoRes.jpg" height="500" alt="An Exquisite Meal" />
                <h1 class="headline-homepage">A dark comedy arthouse thriller western satire.</h1>

            </div>

            <div className="home-content">

            <p class="description"><em>An Exquisite Meal</em> is a sharply satirical genre-bending journey through one absurdly terrifying dinner party in modern America, a darkly playful nightmare that’s full of punchlines and twists.</p>
            
            

                
                <div class="video-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/AENTcJJdpTo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>

                <h3><blockquote>"The absurdist approach may frustrate those seeking more conventional storytelling, or a proper narrative for that matter. But for a sharpened skewering of internet-age snobbery, An Exquisite Meal is just the taste audiences are hoping to devour." --Film Threat</blockquote></h3>
                
                <blockquote>"It may not be what you’re expecting or what you’re used to, but “An Exquisite Meal” promises to take you on a wild, unpredictable ride. Writer/director Robert Bruce Carter is not afraid to present morbidity in a non-conventional, surrealistic manner in his first full-length feature. Add an almost Pythonesque sense of humor, and you’ve got something really special." --Tubi Tuesday</blockquote>

                

                <p></p>
                <p></p>
                <p></p>

                <h2>Stream the Film in North America</h2>
                <div class="streaming-platforms">

                    <a href="https://www.apple.co/3wNBZRN" target="_blank">
                        <img class="streaming-logo" src="../streaming-logos/itunes-png.png" alt="Open in iTunes" />
                    </a>
                    <a href="https://www.amazon.com/Exquisite-Meal-Mike-Jimerson/dp/B09MMPKP2G/" target="_blank">
                        <img class="streaming-logo" src="../streaming-logos/Prime_Video.png" alt="Prime Video" />
                    </a>
                    <a href="https://play.google.com/store/movies/details/An_Exquisite_Meal?id=ALdOfFC5Nhw.P" target="_blank">
                        <img class="streaming-logo" src="../streaming-logos/google play-png.png" alt="Google Play" />
                    </a>

                    <a href="https://www.youtube.com/watch?v=wdpZsfhiA-I" target="_blank">
                        <img class="streaming-logo" src="../streaming-logos/youtube-png.png" alt="YouTube" />
                    </a>

                    <a href="https://vimeo.com/ondemand/anexquisitemeal2/649664493" target="_blank">
                        <img class="streaming-logo" src="../streaming-logos/vimeo-png.png" alt="Vimeo on Demand" />
                    </a>

                    <a href="https://www.vudu.com/content/movies/details/An-Exquisite-Meal/1960390" target="_blank">
                        <img class="streaming-logo" src="../streaming-logos/vudu-png.png" alt="Vudu" />
                    </a>

                    {/* <img class="streaming-logo" src="../streaming-logos/microsoft-png.png" alt="Microsoft" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/playstation-png.png" alt="Playstation" /> */}

                    {/* <img class="streaming-logo" src="../streaming-logos/WOW-png.png" alt="WOW" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/Verizon_Fios-png.png" alt="Verizon" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/armstrong-png.png" alt="Armstrong" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/baker-taylor-png.png" alt="Baker & Taylor" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/comcast-png.png" alt="Comcast" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/Cox_communications-png.png" alt="Cox Communications" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/dish-network-png.png" alt="Dish" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/Frontier Communications-png.png" alt="Frontier" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/mediacom-png.png" alt="Mediacom" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/midwesttape-png.png" alt="Midwest Tape" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/Rogers-png.png" alt="Rogers" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/Shaw-png.png" alt="Shaw" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/Spectrum-png.png" alt="Spectrum" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/libraryboundinc-png.png" alt="Library Bound" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/Suddenlink-png.png" alt="Suddenlink" /> */}
                    {/* <img class="streaming-logo" src="../streaming-logos/alliance-png.png" alt="Alliance" /> */}

                </div>

                               
                

                <h2>Stream the Film Internationally</h2>
                
                <div class="video-container">
                    <iframe width="560" height="315" src="https://player.vimeo.com/video/833475816?h=c585f84b6a" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
                


                <p><strong>Stay in the loop.</strong></p>


                <div class="form-box-homepage">
                    <form name="newsletter" method="POST" action="/thank-you-newsletter/" encType="application/x-www-form-urlencoded" data-netlify-recaptcha="true" data-netlify="true">

                        <input type="hidden" name="form-name" value="newsletter" />
                        <p><label>Your Name: <input type="text" name="name" /></label></p>
                        <p><label>Your Email: <input type="email" name="email" /></label></p>
                        <br />
                        <ReCAPTCHA sitekey="6Ld3X9UUAAAAAKdmMfH-uTHWSOb0Jkvhz5PJaVD8" />
                        <br /><br />
                        <button type="submit" class="btn btn-green">Subscribe</button>
                    </form>
                </div>

                <div class="laurels">
                    <img src="../CQFF_Laurels_2020Selection.png" width="300" alt="Cinequest Laurels" />
                    <img src="../LAUREL_BEST_FEATURE_FILM_GREYSCALE.png" width="300" alt="Threadbare Mitten laurel" />
                </div>


                <br />
            </div>

        </div>
        <Footer>
        </Footer>
    </Container>
)


